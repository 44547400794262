/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

function initGTM() {
  if (window.isGTMLoaded) {
    return false
  }

  window.isGTMLoaded = true

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_MEASUREMENT_ID}`

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("config", `${process.env.GOOGLE_MEASUREMENT_ID}`)
  }

  document.head.appendChild(script)
}

function loadGTM(event) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, loadGTM)
}

exports.onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      setTimeout(initGTM, 3500)
    }
  }

  document.addEventListener("scroll", loadGTM)
  document.addEventListener("mousemove", loadGTM)
  document.addEventListener("touchstart", loadGTM)
}